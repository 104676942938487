////
/// GCA SASS Variables.
/// @group GCA_Config
/// @author Charles Harwood
////

// Base Colours //
$cl__white:				#ffffff;
$cl__black:				#000000;

// Branding Colours //
$cl__blue:				#5d7aa8;
$cl__blue--btn:			#506895;
$cl__blue--banner:		#44577e;
$cl__gold:				#f9ce00;
$cl__gold--hover:		#ffd921;
$cl__gold--active:		#ebc300;
$cl__red:				#e5242a;
$cl__teal:				#73cabb;

// Template Colours //
$cl__text:				rgba($cl__black, 0.67);
$cl__text__input:		rgba($cl__black, 0.57);
$cl__input__bg:			#f1f3f4;
$cl__input__bg--check:	#f7f8f9;
$cl__input__bg--focus:	#e0e3e6;
$cl__input__bd--check:	#7f7f7f;

// Fonts //
$font__primary: 'URWGeometric', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Structure //

// Grid Breakpoints (based on Bootstrap 4 grid) //
$min-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

$max-breakpoints: (
	xs: 0,
	sm: 575px,
	md: 767px,
	lg: 991px,
	xl: 1199px
) !default;

// Max Container Widths for each breakpoint //
$container-widths: (
	xs: 100%,
	sm: 100%,
	md: 40rem,
	lg: 40rem,
	xl: 40rem
) !default;

// REM Base //
$rem-base: 20px;
