/**
 * Custom Component - Terms Content - CH
 */

.modal {
	-webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
}

.terms-content {
	@include font(16px);

	@include min-screen(md) {
		padding: rem(15px);
	}

	h6 {
		font-weight: 700;
	}

	a {
		word-break: break-word;
		word-wrap: break-word;
	}

	iframe {
		width: 100%;
		height: rem(490px);
		border: 0;
	}
}

ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
	counter-reset: item;

	> li {
		display: table;
		margin-bottom: rem(30px);
		counter-increment: item;

		&:before {
			display: table-cell;
			padding-right: 0.6em;
			content: counters(item, '.') '. ';
		}
	}
}

li {
	ol > li {
		margin-top: rem(10px);
		margin-bottom: 0;

		&:before {
			content: counters(item, '.') '. ';
		}
	}
}
