/**
 * Base Typography Styles - CH
 */

/* Root Elements - CH */

html {
	font-size: $rem-base;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased; // sass-lint:disable-line no-vendor-prefixes
	-webkit-backface-visibility: hidden; // sass-lint:disable-line no-vendor-prefixes
	backface-visibility: hidden;

	@include max-screen(sm) {
		font-size: 5.333333333vw;
	}
}

body {
	color: $cl__text;
	font-family: $font__primary;
	line-height: 1.2;
	overflow-x: hidden;
}

/* Headings - CH */

h1 {
	margin-top: em(32px, 32px);
	margin-bottom: em(20px, 32px);
	color: $cl__blue;
	@include font(32px, 38px, 700);
	text-align: center;

	@include min-screen(md) {
		margin-top: em(44px, 48px);
		margin-bottom: em(32px, 48px);
		@include font(48px, 58px);
	}
}

h2 {
	margin-top: em(20px, 24px);
	margin-bottom: em(16px, 24px);
	@include font(24px, 30px, 600);
	text-align: center;

	@include min-screen(md) {
		margin-top: em(44px, 32px);
		margin-bottom: em(25px, 32px);
		@include font(32px, 38px);
	}
}

/* Links - CH */

a:not(.btn) {
	@include transition(color, 0.2s, ease-in-out);
	color: $cl__text;
	text-decoration: underline;

	&:hover {
		color: $cl__blue;
	}

	&.external-link {
		position: relative;

		&:after {
			display: inline-block;
			width: rem(14px);
			height: rem(14px);
			margin: 0 rem(4px);
			background-image: url('../images/ico-new-window.svg');
			content: '';
		}
	}
}

/* Buttons - CH */

.btn {
	display: inline-block;
	@include transition(all, 0.2s, ease-in-out);
	padding: em(22px 23px 24px, 24px);
	border: 0;
	border-radius: rem(5px);
	@include font(24px, 24px, 700);
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;

	// Default States //

	@include hover-focus() {
		text-decoration: none;
	}

	&:focus {
		outline: 0;
	}

	&:not(:disabled) {
		cursor: pointer;
	}

	// Responsive //

	@include min-screen(md) {
		padding: em(21px 22.4px 23.8px, 28px);
		@include font(28px, 34px);
	}

	// Button Variants //

	&.btn-primary {
		background-color: $cl__gold;
		color: $cl__blue--btn;

		@include hover-focus() {
			background-color: $cl__gold--hover;
		}

		&:not(:disabled):active {
			background-color: $cl__gold--active;
		}

		&:disabled {
			background-color: $cl__input__bg--focus;
			color: $cl__input__bd--check;
			cursor: not-allowed;
		}
	}

	&.btn-block {
		display: block;
		width: 100%;

		+ .btn-block {
			margin-top: rem(20px);
		}
	}

	&.btn-reset {
		margin-top: rem(40px);
		margin-bottom: rem(50px);

		@include min-screen(md) {
			margin-top: rem(70px);
			margin-bottom: rem(60px);
		}
	}
}

input[type='submit'],
input[type='reset'],
input[type='button'] {
	&.btn-block {
		width: 100%;
	}
}
