/**
 * Custom Component - Banner - CH
 */

.gca__banner {
	position: relative;
	text-align: center;

	&__image {
		display: block;
		width: rem(335px);
		max-width: 100%;
		margin: 0 auto;
	}

	&__text {
		position: absolute;
		top: rem(8px);
		width: 100%;

		p {
			margin: 0;
			color: $cl__blue--banner;
			@include font(16px, 20px);
		}
	}

	small {
		display: block;
		margin-top: em(18px, 13px);
		@include font(13px, 16px);
	}

	@include min-screen(md) {
		&__image {
			width: rem(380px);
		}

		&__text {
			top: rem(11px);

			p {
				@include font(18px, 22px);
			}
		}

		small {
			margin-top: em(20px, 15px);
			@include font(15px, 18px);
		}
	}

	@media (max-width: 374px) {
		&__text {
			top: 1.6vw;
		}
	}
}
