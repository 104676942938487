/*!
 * Gold Coast Airport
 * Customer Rewards Promotion React Application.
 * @author Charles Harwood
 */

// Bootstrap 4 (Reboot & Grid Framework only).
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/bootstrap-grid';

// SASS partials.
@import 'config/variables';
@import 'config/functions';
@import 'config/mixins';

// Base styles.
@import 'base/fonts';
@import 'base/structural';
@import 'base/typography';
// Component styles.
@import 'components/header';
@import 'components/banner';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/custom-forms';
@import 'components/entry-form';
@import 'components/footer';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import 'components/terms';
@import '~bootstrap/scss/utilities';
