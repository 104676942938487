/**
 * Custom Component - Entry Form - CH
 */

.gca__entry-form {

	.gca__form {
		h6 {
			font-weight: 700;
		}

		label:not(.custom-control-label) {
			position: absolute;
			top: rem(18px);
			left: rem(15px);
			@include transition();
			color: $cl__text__input;
			will-change: transform, font-size;

			@include min-screen(md) {
				top: rem(23px);
				left: rem(20px);
			}
		}

		.form-control, .custom-select {
			height: auto;
			padding: em(15px);
			border: 0;
			background-color: $cl__input__bg;
			color: $cl__text__input;

			&:focus {
				background-color: $cl__input__bg--focus;
				color: $cl__text;
				box-shadow: none;
			}

			&:focus, &:not([value='']):not(textarea), &.form-control--has-value {
				color: $cl__text;

				~ label:not(.custom-control-label) {
					transform: translateY(-90%);
					font-size: 0.75em;
				}
			}

			&.is-invalid {
				border-bottom: rem(2px) solid $cl__red !important;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		.custom-select {
			background-image: url('../images/ico-select-arrow-down.svg');
			background-position: calc(100% - 1.5rem) center;
			background-size: rem(14px);

			~ label {
				display: none;
			}

			&::-ms-value { // sass-lint:disable-line no-vendor-prefixes
				background: transparent;
			}
		}

		.custom-control.custom-checkbox {
			padding: rem(14px 15px 18px);
			border-radius: rem(5px);
			background-color: $cl__input__bg--check;

			.custom-control-label {
				display: block;
				margin-left: rem(34px);
				color: $cl__text__input;
				cursor: pointer;

				&:before, &:after {
					top: 0;
					left: rem(-34px);
					width: rem(25px);
					height: rem(25px);
					box-shadow: none;
					cursor: pointer;
				}

				&:before {
					border: rem(3px) solid $cl__input__bd--check;
					background-color: initial;
				}

				@include max-screen(sm) {
					@include font(18px, 24px);
				}
			}

			.custom-control-input {
				&:checked {
					~ .custom-control-label {
						&:before {
							background-color: $cl__input__bd--check;
						}

						&:after {
							background-image: url('../images/ico-check.svg');
							background-size: 75%;
						}
					}
				}

				&.is-invalid ~ .invalid-feedback {
					margin-top: rem(15px);
				}
			}
		}

		hr {
			margin-top: rem(40px);
			margin-bottom: rem(30px);
			border-top-width: rem(2px);
		}

		&__star-rating {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
			max-width: rem(335px);
			margin: 0 auto;

			svg {
				flex-basis: rem(45px);
				max-width: rem(45px);
				@include transition(fill, 0.2s, ease-in-out);
				cursor: pointer;
			}

			~ .invalid-feedback {
				display: block;
				margin-top: rem(30px);
			}
		}

		.invalid-feedback {
			color: $cl__red;
		}

		.btn[type='submit'] {
			margin-top: em(58px, 24px);
		}

		@include min-screen(md) {
			.form-control, .custom-select {
				padding: em(20px);
			}

			.custom-control.custom-checkbox {
				padding: em(18px 21px);

				.custom-control-label {
					margin-left: rem(44px);

					&:before, &:after {
						left: rem(-44px);
					}
				}
			}

			hr {
				margin-top: rem(50px);
				margin-bottom: rem(38px);
			}

			&__star-rating {
				max-width: rem(494px);
				margin-top: rem(30px);

				svg {
					flex-basis: rem(70px);
					max-width: rem(70px);
				}
			}

			.btn[type='submit'] {
				margin-top: em(48px, 28px);
				margin-bottom: em(70px, 28px);
			}
		}
	}
}
