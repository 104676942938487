////
/// GCA SASS Mixins.
/// @group GCA_Config
/// @author Charles Harwood
////

/// Return `font-size` in both REM and PX for browser compatibility.
/// @param {integer} $size-value [1] - The desired value in REM, without the units
/// @requires {variable} $rem-base
/// @output A REM font-size value with PX fallback
@mixin font-size($size-value: 1) {
	// sass-lint:disable no-duplicate-properties
	font-size: ($size-value * $rem-base);
	font-size: $size-value * 1rem;
}

/// Center a block in its parent.
@mixin center-block() {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

/// Button Colour Mixin.
/// Can pass in colour values, or use without params to use default variable values.
/// @param {color} $text-colour [$cl__text--default] - The colour value for the button text
/// @param {color} $bg-colour [$cl__off-white] - The colour value for the button background
/// @param {color} $border-colour [transparent] - The (optional) colour value for the button border
/// @requires {variable} $cl__text--default - The default value for `color`
/// @requires {variable} $cl__off-white - The default value for `background`
@mixin btn($text-colour: $cl__text--default, $bg-colour: $cl__off-white, $border-colour: null) {
	@if $border-colour !=null {
		border-color: $border-colour;
	} @else {
		border-color: transparent;
	}

	background: $bg-colour;
	color: $text-colour;
}

/// Transition Mixin.
/// Can pass in transition-property, transition-duration and transition-timing-function values,
/// or include without params to use default values.
/// @param {string} $property [all] - The CSS property to apply our transition to
/// @param {time} $duration [0.3s] - How long we want our transition to take to complete
/// @param {string} $timing-function [ease] - The timing function or cubic bezier curve to use for our transition
@mixin transition($property: all, $duration: 0.3s, $timing-function: ease) {
	transition: $property $duration $timing-function;
}

/// Font Styling Mixin.
/// Can pass in font-size, font-weight, line-height, letter-spacing,
/// or include without params to use default values.
/// @param {length} $size [$rem-base] - The value for `font-size` (will be converted to EMs)
/// @param {length} $lheight [null] - The value for `line-height` (will be converted to EMs)
/// @param {integer} $weight [null] - The value for `font-weight` (e.g. 300, 400, etc)
/// @param {length} $lspacing [null] - The value for `letter-spacing` (no conversion)
/// @requires {variable} $rem-base - The default value for `font-size`
@mixin font($size: $rem-base, $lheight: null, $weight: null, $lspacing: null) {
	@if $size !=null {
		font-size: em($size);
	}

	@if $weight !=null {
		font-weight: $weight;
	}

	@if $lheight !=null {
		line-height: em($lheight, $size);
	}

	@if $lspacing !=null {
		letter-spacing: $lspacing;
	}
}

@mixin font-rem($size: $rem-base, $lheight: null, $weight: null, $lspacing: null) {
	@if $size !=null {
		font-size: rem($size);
	}

	@if $weight !=null {
		font-weight: $weight;
	}

	@if $lheight !=null {
		line-height: em($lheight, $size);
	}

	@if $lspacing !=null {
		letter-spacing: $lspacing;
	}
}

/// Cover Mixin.
/// Position an element to completely cover/fill its next relatively-positioned parent in the DOM tree.
/// @param {integer} $zindex - The value for the `z-index` property
@mixin cover($zindex) {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: $zindex;
}

/// Vertical Alignment Mixin.
/// Position an element in the vertical center of its next relatively-positioned parent in the DOM tree.
/// @param {string} $position [relative] - The value for the `position` property (either relative or absolute)
@mixin va($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}

/// Center Alignment Mixin.
/// Position an element in the vertical and horizontal center of its next relatively-positioned parent in the DOM tree.
/// @param {string} $position [relative] - The value for the `position` property (either relative or absolute)
@mixin position-center($position: relative) {
	position: $position;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/// Opacity Mixin.
/// Provides an IE8 fallback for `opacity` values.
/// @param {integer} $opacity - The value for the `opacity` property
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

/// Min-Width Media Query Mixin.
/// Creates a media query with a `min-width` rule.
/// @param {length} $name - The name of the desired breakpoint
/// @param {array}  $breakpoints [$min-breakpoints] - The array of breakpoints
@mixin min-screen($name, $breakpoints: $min-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

/// Max-Width Media Query Mixin.
/// Creates a media query with a `max-width` rule.
/// @param {length} $name - The name of the desired breakpoint
/// @param {array}  $breakpoints [$max-breakpoints] - The array of breakpoints
@mixin max-screen($name, $breakpoints: $max-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

/// Full Media Query Mixin.
/// Creates a media query with both a `min-width` and `max-width` rule.
/// @param {length} $lower - The `min-width` breakpoint.
/// @param {length} $upper - The `max-width` breakpoint.
/// @param {array}  $lower-breakpoints [$min-breakpoints] - The array of `min-width` breakpoints.
/// @param {array}  $upper-breakpoints [$max-breakpoints] - The array of `max-width` breakpoints.
@mixin screen($lower, $upper, $lower-breakpoints: $min-breakpoints, $upper-breakpoints: $max-breakpoints) {
	$min: breakpoint-min($lower, $lower-breakpoints);
	$max: breakpoint-max($upper, $upper-breakpoints);

	@media (min-width: $min) and (max-width: $max) {
		@content;
	}
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {

	@each $breakpoint,
	$container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}
