/**
 * Base Structural Styles - CH
 */

// Container widths.
.container {
	padding-right: rem(20px);
	padding-left: rem(20px);
	@include make-container-max-widths($container-widths, $min-breakpoints);
}

// Page Transitions handled by ReactTransitionGroup.
.gca__transition-wrapper {
	opacity: 0;

	&.fade-enter-done,
	&.fade-appear-done {
		opacity: 1;
	}
}

.fade-enter,
.fade-appear {
	opacity: 0;
	z-index: 1;

	&.fade-enter-active,
	&.fade-appear-active {
		transition: opacity 0.5s ease-in;
		transition-delay: 0.3s;
		opacity: 1;
	}
}

.fade-exit {
	opacity: 1;

	&.fade-exit-active {
		transition: opacity 0.5s ease-in;
		opacity: 0;
	}
}
