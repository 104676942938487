/**
 * Custom Component - Footer - CH
 */

.gca__footer {
	padding-top: rem(60px);
	padding-bottom: rem(60px);
	text-align: center;

	a {
		@include transition(opacity, 0.2s, ease-in-out);

		@include hover-focus() {
			opacity: 0.8;
		}
	}

	img {
		width: rem(120px);
		max-width: 100%;
	}

	@include min-screen(md) {
		padding-top: rem(50px);
		padding-bottom: rem(70px);

		img {
			width: rem(150px);
		}
	}
}
