/**
 * Base Fonts Configuration - CH
 */

/* sass-lint:disable no-duplicate-properties */

/* URW Geometric - Light (300) */

@font-face {
	font-family: 'URWGeometric';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/urwgeometric-light.eot');
	src: url('../fonts/urwgeometric-light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/urwgeometric-light.woff2') format('woff2'),
	url('../fonts/urwgeometric-light.woff') format('woff'),
	url('../fonts/urwgeometric-light.ttf') format('truetype'),
	url('../fonts/urwgeometric-light.svg#urw_geometriclight') format('svg');
}

/* URW Geometric - Regular (400) */

@font-face {
	font-family: 'URWGeometric';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/urwgeometric-regular.eot');
	src: url('../fonts/urwgeometric-regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/urwgeometric-regular.woff2') format('woff2'),
	url('../fonts/urwgeometric-regular.woff') format('woff'),
	url('../fonts/urwgeometric-regular.ttf') format('truetype'),
	url('../fonts/urwgeometric-regular.svg#urw_geometricregular') format('svg');
}

/* URW Geometric - Semi-bold (600) */

@font-face {
	font-family: 'URWGeometric';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/urwgeometric-semibold.eot');
	src: url('../fonts/urwgeometric-semibold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/urwgeometric-semibold.woff2') format('woff2'),
	url('../fonts/urwgeometric-semibold.woff') format('woff'),
	url('../fonts/urwgeometric-semibold.ttf') format('truetype'),
	url('../fonts/urwgeometric-semibold.svg#urw_geometricsemi_bold') format('svg');
}

/* URW Geometric - Bold (700) */

@font-face {
	font-family: 'URWGeometric';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/urwgeometric-bold.eot');
	src: url('../fonts/urwgeometric-bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/urwgeometric-bold.woff2') format('woff2'),
	url('../fonts/urwgeometric-bold.woff') format('woff'),
	url('../fonts/urwgeometric-bold.ttf') format('truetype'),
	url('../fonts/urwgeometric-bold.svg#urw_geometricbold') format('svg');
}
