/**
 * Custom Component - Header - CH
 */

.gca__header {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: center;
	height: rem(240px);
	background-repeat: repeat;
	background-position: 0 0;
	background-size: contain;
	overflow: hidden;

	img {
		width: rem(470px);
	}

	@include min-screen(md) {
		height: rem(380px);

		img {
			width: rem(820px);
		}
	}
}
